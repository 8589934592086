var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.categoryListVisible,
            callback: function ($$v) {
              _vm.categoryListVisible = $$v
            },
            expression: "categoryListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.categoryList,
              radio: _vm.report.category,
              title: "报销类型",
            },
            on: { select: _vm.handleCategoryListSelect },
          }),
        ],
        1
      ),
      _c("van-calendar", {
        attrs: { "default-date": _vm.report.reiTime },
        on: { confirm: _vm.selectStage },
        model: {
          value: _vm.stageVisible,
          callback: function ($$v) {
            _vm.stageVisible = $$v
          },
          expression: "stageVisible",
        },
      }),
      _c(
        "div",
        { staticClass: "oa-report" },
        [
          _c("div", { staticClass: "report-list" }, [
            _c("div", { staticClass: "report" }, [
              _c(
                "div",
                { staticClass: "report-money" },
                [
                  _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      label: "报销金额（元）",
                      "input-align": "right",
                      placeholder: "请输入报销金额",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.show = true
                      },
                    },
                    model: {
                      value: _vm.report.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.report, "money", $$v)
                      },
                      expression: "report.money",
                    },
                  }),
                  _c("van-number-keyboard", {
                    attrs: {
                      show: _vm.show,
                      theme: "custom",
                      "extra-key": ".",
                      "close-button-text": "完成",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.show = false
                      },
                      input: _vm.onInput,
                      delete: _vm.onDelete,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "report-date" }, [
                _c("div", [_vm._v("日期")]),
                _c(
                  "div",
                  {
                    staticStyle: { color: "#0079FE", "margin-left": "20px" },
                    on: { click: _vm.switchStage },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.timestampToTime(_vm.report.reiTime.getTime())
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              _vm.report.category === "CASE"
                ? _c(
                    "div",
                    {
                      staticClass: "report-casename",
                      on: { click: _vm.switchCaseList },
                    },
                    [_c("div", [_vm._v(" " + _vm._s(_vm.caseName) + " ")])]
                  )
                : _vm._e(),
              _c("div", { staticClass: "report-type" }, [
                _c(
                  "div",
                  { on: { click: _vm.switchCategoryList } },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.categoryFmt(_vm.report.category))),
                    ]),
                    _c("van-icon", {
                      attrs: { name: "arrow-down", color: "#CCCCCC" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c("van-field", {
                      attrs: {
                        label: "报销类别",
                        "input-align": "right",
                        placeholder: "如：打车费、交通费",
                      },
                      model: {
                        value: _vm.report.reimType,
                        callback: function ($$v) {
                          _vm.$set(_vm.report, "reimType", $$v)
                        },
                        expression: "report.reimType",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "report-textarea" },
                [
                  _c("van-field", {
                    attrs: {
                      rows: "3",
                      autosize: "",
                      label: "费用明细",
                      type: "textarea",
                      placeholder: "请输入费用明细描述",
                    },
                    model: {
                      value: _vm.report.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.report, "description", $$v)
                      },
                      expression: "report.description",
                    },
                  }),
                  _c("i", {
                    staticClass: "iconfont icon-link",
                    on: { click: _vm.addImg },
                  }),
                ],
                1
              ),
              _vm.report.collections.length > 0
                ? _c("div", { staticClass: "oa-receive-content-box" }, [
                    _c(
                      "div",
                      { staticClass: "oa-receive-content-box-resource" },
                      _vm._l(_vm.report.collections, function (items, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "resource-image-box" },
                          [
                            items.type === "WORD"
                              ? _c("i", {
                                  staticClass: "iconfont icon-word",
                                  staticStyle: { color: "rgb(108, 168, 255)" },
                                })
                              : _vm._e(),
                            items.type === "EXCEL"
                              ? _c("i", {
                                  staticClass: "iconfont icon-exl",
                                  staticStyle: { color: "rgb(139, 221, 108)" },
                                })
                              : _vm._e(),
                            items.type === "PDF"
                              ? _c("i", {
                                  staticClass: "iconfont icon-PDF",
                                  staticStyle: { color: "rgb(62, 179, 240)" },
                                })
                              : _vm._e(),
                            items.type === "VIDEO"
                              ? _c("i", {
                                  staticClass: "iconfont icon-shipin",
                                  staticStyle: { color: "rgb(75, 158, 251)" },
                                })
                              : _vm._e(),
                            items.type === "AUDIO"
                              ? _c("i", {
                                  staticClass: "iconfont icon-shipin",
                                  staticStyle: { color: "rgb(75, 158, 251)" },
                                })
                              : _vm._e(),
                            items.type === "IMAGE"
                              ? _c("img", {
                                  staticClass: "resource-image-img",
                                  attrs: { src: items.content, alt: "" },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "resource-image-delete",
                              attrs: {
                                src: require("../../../../../public/img/icons/icon_delete.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteImg(index)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.report.progress === "UNAUDITED" ||
          _vm.report.progress === "APPROVED" ||
          _vm.report.progress === "CERTIFIED_FAILED"
            ? _c(
                "div",
                { staticClass: "oa-approval" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "oa-approval-title",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [_vm._v("审批记录")]
                  ),
                  _c("approval-record", {
                    attrs: {
                      typeId: this.id,
                      type: "REIMBURSEMENT_RECORD_ADD",
                    },
                  }),
                ],
                1
              )
            : _vm.approvalUserComp
            ? _c("approval-user", {
                attrs: {
                  Approval: this.Approval,
                  CC: this.CC,
                  isAdd: _vm.isAdd,
                  approvalUserSource: _vm.approvalUser,
                  ccUserSource: _vm.ccUser,
                },
                on: { userSelect: _vm.userSelect, coopSelect: _vm.coopSelect },
              })
            : _vm._e(),
          _vm.operateBtnShow
            ? _c("operate-btn", {
                attrs: {
                  isAdd: _vm.isAdd,
                  progress: _vm.report.progress,
                  approveRecordUsers: _vm.report.approveRecordUsers,
                  Approval: this.Approval,
                  CC: this.CC,
                },
                on: { save: _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }