<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backList(0)"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <!-- <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button> -->
    </fb-header>
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list :caseId="caseId" @select="handleCaseListSelect" />
    </van-popup>
    <!-- 报销类型 -->
    <van-popup
      v-model="categoryListVisible"
      position="top"
      get-container="body"
    >
      <stamp-type
        :options="categoryList"
        :radio="report.category"
        title="报销类型"
        @select="handleCategoryListSelect"
      />
    </van-popup>
    <!-- 日期组件 -->
    <van-calendar
      v-model="stageVisible"
      @confirm="selectStage"
      :default-date="report.reiTime"
    />
    <div class="oa-report">
      <div class="report-list">
        <div class="report">
          <!-- <div class="report-top">
            <div class="report-top-user">
              <img :src="applicantAvatar" alt="" />
              <div>{{ applicantName }}</div>
            </div>
            <i></i>
          </div> -->
          <div class="report-money">
            <van-field
              v-model="report.money"
              readonly
              clickable
              label="报销金额（元）"
              input-align="right"
              placeholder="请输入报销金额"
              @touchstart.stop="show = true"
            />
            <van-number-keyboard
              :show="show"
              theme="custom"
              extra-key="."
              close-button-text="完成"
              @blur="show = false"
              @input="onInput"
              @delete="onDelete"
            />
          </div>
          <div class="report-date">
            <div>日期</div>
            <div style="color:#0079FE;margin-left:20px" @click="switchStage">
              {{ timestampToTime(report.reiTime.getTime()) }}
            </div>
          </div>
          <div
            class="report-casename"
            v-if="report.category === 'CASE'"
            @click="switchCaseList"
          >
            <div>
              {{ caseName }}
            </div>
          </div>
          <div class="report-type">
            <div @click="switchCategoryList">
              <span>{{ categoryFmt(report.category) }}</span>
              <van-icon name="arrow-down" color="#CCCCCC" />
            </div>
            <div style="flex:1">
              <van-field
                v-model="report.reimType"
                label="报销类别"
                input-align="right"
                placeholder="如：打车费、交通费"
              />
            </div>
          </div>
          <div class="report-textarea">
            <van-field
              v-model="report.description"
              rows="3"
              autosize
              label="费用明细"
              type="textarea"
              placeholder="请输入费用明细描述"
            />
            <i class="iconfont icon-link" @click="addImg"></i>
          </div>
          <div
            class="oa-receive-content-box"
            v-if="report.collections.length > 0"
          >
            <div class="oa-receive-content-box-resource">
              <div
                class="resource-image-box"
                v-for="(items, index) in report.collections"
                :key="index"
              >
                <i
                  v-if="items.type === 'WORD'"
                  class="iconfont icon-word"
                  style="color: rgb(108, 168, 255);"
                ></i>
                <i
                  v-if="items.type === 'EXCEL'"
                  class="iconfont icon-exl"
                  style="color: rgb(139, 221, 108);"
                ></i>
                <i
                  v-if="items.type === 'PDF'"
                  class="iconfont icon-PDF"
                  style="color: rgb(62, 179, 240);"
                ></i>
                <i
                  v-if="items.type === 'VIDEO'"
                  class="iconfont icon-shipin"
                  style="color: rgb(75, 158, 251);"
                ></i>
                <i
                  v-if="items.type === 'AUDIO'"
                  class="iconfont icon-shipin"
                  style="color: rgb(75, 158, 251);"
                ></i>
                <img
                  v-if="items.type === 'IMAGE'"
                  :src="items.content"
                  alt=""
                  class="resource-image-img"
                />
                <img
                  src="../../../../../public/img/icons/icon_delete.png"
                  alt=""
                  class="resource-image-delete"
                  @click="deleteImg(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="oa-approval"
        v-if="
          report.progress === 'UNAUDITED' ||
            report.progress === 'APPROVED' ||
            report.progress === 'CERTIFIED_FAILED'
        "
      >
        <div class="oa-approval-title" style="margin-bottom:20px">审批记录</div>
        <approval-record :typeId="this.id" :type="'REIMBURSEMENT_RECORD_ADD'" />
      </div>
      <approval-user
        v-else-if="approvalUserComp"
        :Approval="this.Approval"
        :CC="this.CC"
        :isAdd="isAdd"
        :approvalUserSource="approvalUser"
        :ccUserSource="ccUser"
        @userSelect="userSelect"
        @coopSelect="coopSelect"
      />
      <operate-btn
        v-if="operateBtnShow"
        :isAdd="isAdd"
        :progress="report.progress"
        :approveRecordUsers="report.approveRecordUsers"
        :Approval="this.Approval"
        :CC="this.CC"
        @save="save"
      />
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'

import { gettype } from '../../common/fileType'
import OperateBtn from '../../components/operateBtn'
import ApprovalRecord from '../../components/approvalRecord'
import ApprovalUser from '../../components/approvalUser'
import { mapState, mapActions, mapMutations } from 'vuex'
import { qiniuBase } from '~api-config'
import CaseList from '../../components/caseList'
import StampType from '../../components/stampType'

export default {
  name: 'oa-addReport',
  mixins: [goBackMixin],
  components: {
    StampType,
    CaseList,
    ApprovalRecord,
    OperateBtn,
    ApprovalUser
  },
  data() {
    return {
      show: false,
      id: 0,
      title: '报销',
      // applicantName: this.userInfo.nickName,
      // applicantAvatar: this.userInfo.avatarUrl,
      // applicantId: this.userInfo.id,
      caseListVisible: false, // 案件列表弹框
      caseName: '',
      caseId: 0,
      categoryListVisible: false,
      categoryList: [
        {
          value: 'LAWFIRM',
          text: '律所报销'
        },
        {
          value: 'CASE',
          text: '案件报销'
        }
      ],
      report: {
        money: '',
        reimType: '',
        reiTime: new Date(),
        description: '',
        collections: [],
        category: 'LAWFIRM'
      },
      isAdd: 0,

      CC: [], // 抄送人
      Approval: [], // 审批人
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      approvalUserComp: false, // 是否渲染审批人组件
      coopUser: null,
      stageVisible: false,
      operateBtnShow: false
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaTab']),
    // 数字键盘组件删除事件
    onDelete(value) {
      var money = this.report.money.toString()
      this.report.money = money.substring(0, money.length - 1)
    },
    // 数字键盘组件输入事件
    onInput(value) {
      this.report.money = this.report.money + '' + value
    },
    // 返回列表页面
    backList(time = 1000) {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.setOaTab('2')
        setTimeout(() => {
          this.$router.go(-1) // 返回上一层
        }, time)
      }
    },
    // 项目名称点击事件
    switchCaseList() {
      if (this.isAdd === 0) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      console.log(options)
      this.caseName = options.label
      this.caseId = options.caseId
      this.switchCaseList()
    },
    // 报销方式格式化
    categoryFmt(code) {
      const dict = {
        LAWFIRM: '律所报销',
        CASE: '案件报销'
      }
      return dict[code]
    },
    // 报销方式点击事件
    switchCategoryList() {
      this.categoryListVisible = !this.categoryListVisible
    },
    handleCategoryListSelect(options) {
      this.report.category = options.value
      this.switchCategoryList()
      if (options.value === 'CASE') {
        this.switchCaseList()
      }
    },
    // 获取审批人和抄送人
    async getUsers() {
      this.approvalUserComp = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: '默认',
        type: 'REIMBURSEMENT_RECORD_ADD'
      }
      const res = await this.$axios.post(
        `${this.$base}/management/org/advice/user`,
        params
      )
      this.approvalUserComp = true
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach(item => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        if (this.isAdd === 0) {
          this.ccUser = arrlist[0]
          this.approvalUser = arr[0]
        }
      }
    },
    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },
    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      this.getUsers()
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(parseInt(cjsj)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var h =
      //   ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      // var m =
      //   date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D
    },
    // 日期点击事件
    switchStage() {
      this.stageVisible = !this.stageVisible
    },
    selectStage(date) {
      console.log(date)
      this.switchStage()
    },
    // 点击添加图片
    addImg(item) {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: file => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              }
            },
            (fileopt, key, file) => {
              console.log(fileopt)
              const url = qiniuBase + '/' + key
              if (!gettype(fileopt.name)) {
                return this.$notify('文件格式不支持')
              }
              var obj = {
                name: fileopt.name,
                type: gettype(fileopt.name),
                content: url,
                size: fileopt.size
              }
              this.report.collections.push(obj)
              document.getElementById('file').remove()
            }
          )
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log(data)
      const list = JSON.parse(data)
      list.map(item => {
        if (!gettype(item.name)) {
          return this.$notify('文件格式不支持')
        }
        var obj = {
          name: item.name,
          type: gettype(item.name),
          content: item.url,
          size: item.size
        }
        this.collections.push(obj)
      })
    },
    deleteImg(index) {
      this.report.collections.splice(index, 1)
    },
    // 删除事件
    deleteReport() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.$base}/management/reimbursement/record/delete/${
              this.report.id
            }`
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            this.backList()
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    // 按钮事件
    save(progress) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      if (progress === 'DELETE') {
        this.deleteReport()
      } else {
        if (this.report.money === '') {
          this.$toast('请输入报销金额')
          return
        }
        if (this.report.category === 'CASE') {
          if (this.caseName === '') {
            this.$toast('请选择案件')
            return
          }
        }
        // 新增
        if (this.isAdd === 0) {
          const userObj = []
          userObj[0] = {
            type: 'APPLICANT',
            user: {
              id: this.userInfo.id
            }
          }
          userObj[1] = {
            type: 'APPROVER',
            user: {
              id: this.approvalUser.userid
            }
          }
          userObj[2] = {
            type: 'RECIPIENT',
            user: {
              id: this.ccUser.userid
            }
          }
          if (this.coopUser) {
            userObj.push({
              type: 'APPROVER',
              user: {
                id: this.coopUser.id
              }
            })
          }
          const params = {
            ...this.report,
            reiTime: this.timestampToTime(this.report.reiTime.getTime()),
            organizationId: this.organizationId,
            causeType: 'LITIGATION',
            progress: progress,
            applicantName: this.userInfo.nickName,
            userId: this.userInfo.id,
            approveRecordUsers: userObj,
            caseId: this.caseId,
            caseName: this.caseName
          }
          this.$axios
            .post(`${this.$base}/management/reimbursement/record`, params)
            .then(res => {
              this.$toast.clear()
              if (res.data.code === 200) {
                this.$toast.success('提交成功')
                this.backList()
              } else {
                // this.$message.error(res.data.message)
              }
            })
            .catch(err => {
              this.$toast.clear()
              console.log(err)
            })
        } else {
          // 修改
          this.updateReport(progress)
        }
      }
    },
    updateReport(progress) {
      // 更新事件
      const userObj = []
      userObj[0] = {
        type: 'APPLICANT',
        user: {
          id: this.userInfo.id
        }
      }
      userObj.push({
        type: 'APPROVER',
        user: {
          id: this.approvalUser.userid
        }
      })
      userObj.push({
        type: 'RECIPIENT',
        user: {
          id: this.ccUser.userid
        }
      })
      const params = {
        ...this.report,
        progress: progress
      }
      let url = ''
      if (
        progress === 'APPROVED' ||
        progress === 'CERTIFIED_FAILED' ||
        progress === 'UNAUDITED'
      ) {
        url = `${this.$base}/management/reimbursement/record/${
          this.report.id
        }/progress?progress=${progress}`
      } else {
        url = `${this.$base}/management/reimbursement/record`
      }
      this.$axios
        .put(url, params)
        .then(res => {
          this.$toast.clear()
          if (res.data.code === 200) {
            this.$toast.success('提交成功')
            this.backList()
          } else {
            // this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$toast.clear()
          console.log(err)
        })
    }
  },
  async created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
    await this.getId()
    this.isAdd = parseInt(this.$route.query.isAdd)
    // isAdd 0表示新增  1表示修改
    if (this.isAdd === 1) {
      this.id = this.$route.query.commonId
      const res = await this.$axios.get(
        `${this.$base}/management/reimbursement/record/${
          this.$route.query.commonId
        }`
      )
      const { code, data } = res.data
      if (code === 200) {
        let avatarUrl = ''
        let applicantName = ''
        data.approveRecordUsers.map((item, i) => {
          if (item.type === 'APPLICANT') {
            avatarUrl = item.user.avatarUrl
            applicantName = item.user.nickName
          }
        })
        data.avatarUrl = avatarUrl
        data.applicantName = applicantName
      } else {
        this.$notify('找不到数据')
      }
      const report = data
      report.reiTime = new Date(report.reiTime)
      this.report = report
      this.caseName = report.caseName
      this.caseId = report.caseId
      this.approveRecordUsers = report.approveRecordUsers
      this.approveRecordUsers.map(item => {
        if (item.type === 'APPROVER') {
          this.approvalUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'APPROVER',
            avatarUrl: item.user.avatarUrl
          }
        } else if (item.type === 'RECIPIENT') {
          this.ccUser = {
            label: item.user.nickName,
            userid: item.user.id,
            type: 'RECIPIENT',
            avatarUrl: item.user.avatarUrl
          }
        }
      })
      this.operateBtnShow = true
    } else {
      this.operateBtnShow = true
    }
  },
  mounted() {}
}
</script>

<style lang="stylus">
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-report
  position relative
  padding-bottom 100px
  & .report-list
    padding 0 10px
    box-sizing border-box
    font-size 14px
    & .report
      width 100%
      border 1px solid #cccccc
      border-radius 5px
      padding 10px 15px
      box-sizing border-box
      margin-bottom 10px
      & .report-top
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        & .report-top-user
          display flex
          align-items center
          & img
            width 32px
            height 32px
            border-radius 50%
            margin-right 10px
        & .icon-shanchu1
          color #999
      & .report-money
        position relative
        & .van-cell
          padding 5px 0px
          box-sizing border-box
          & .van-field__label
            width auto
      & .report-money::before
        color #ee0a24
        font-size 14px
        top 5px
        content '*'
        left -10px
        position absolute
      & .report-date
        display flex
        flex-direction row
        align-items center
      & .report-casename
        padding 5px 0px
        box-sizing border-box
        position relative
        & div
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 1
          overflow hidden
      & .report-casename::before
        left -10px
        top 5px
        position absolute
        color #ee0a24
        font-size 14px
        content '*'
      & .report-type
        display flex
        flex-direction row
        align-items center
        & .van-field__label
          width auto
      & .report-textarea
        display flex
        flex-direction row
        align-items flex-end
        & .van-cell
          padding-left 0px
          padding-right 0px
        & .iconfont
          margin-left 10px
          font-size 20px
      & .oa-receive-content-box
        display flex
        flex-direction row
        justify-content space-between
        align-items center
        margin-top 20px
        & .oa-receive-content-box-resource
          display flex
          flex-direction row
          align-items center
          flex-wrap wrap
          & .resource-image-box
            position relative
            width 70px
            height 70px
            margin-right 10px
            margin-bottom 10px
            & .iconfont
              font-size 70px
            & .resource-image-img
              width 100%
              height 100%
              object-fit contain
            & .resource-image-delete
              position absolute
              width 20px
              height 20px
              right -10px
              top -10px
    & .report-add
      width 100%
      height 30px
      display flex
      flex-direction row
      justify-content center
      align-items center
      font-size 16px
      color #0079FE
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
  & .oa-add-invoice-bottom
    width 100%
    background #FFFFFF
    position fixed
    bottom 0px
    right 0px
    padding 10px 20px
    box-sizing border-box
    display flex
    flex-direction row
    justify-content flex-end
    button
      width 80px
      height 50px
      border-radius 5px
      font-size 16px
    & .oa-button-save
      background none
      border none
      color #0079FE
    & .oa-button-apply
      background #0079FE
      border none
      color #FFFFFF
    & .oa-button-delete
      background none
      border 1px solid #0079FE
      color #0079FE
</style>
